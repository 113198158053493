<template>
    <div class="projectCase_page">
        <Banner :adCategory="3"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="content">
            <div class="main">
                <!-- <NavBar :navBarList="$store.state.projectCase" @changeNavBar="changeNavBar"></NavBar> -->
                <div class="navBar display">
                    <div class="navBar_list display pointer" v-for="(item,index) in $store.state.projectCase"
                        :key="index" @click="changeNavBar(item,index)"
                        :class="navBarIndex == index ? 'navBarActive' : ''">
                        {{item.name}}
                    </div>

                </div>
                <div class="caseList">
                    <ProjectCaseList :companyList="projectCaseList"></ProjectCaseList>

                </div>
                <div class="pages">
                    <pages :params="params" :total="total" @nextPage="nextPage">
                    </pages>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
import Breadcrumb from '@/components/breadcrumb'
import ProjectCaseList from '@/components/projectCaseList'
import NavBar from '@/components/navBar'
import pages from '@/components/pages'
export default {
    name:"projectCase",
    components:{
        Banner,
        Breadcrumb,
        NavBar,
        ProjectCaseList,
        pages
    },
    data(){
        return{
            navBarIndex: 0,
            projectCaseList: [],
            breadcrumbList: [
                {
                    name: "工程案例",
                    url: ''
                },
            ],
            navBarList: [
                {
                    id: 1,
                    name: '工厂物流仓储'
                },
                {
                    id: 2,
                    name: '市政交通'
                },
                {
                    id: 3,
                    name: '文体教育'
                }, 
                {
                    id: 4,
                    name: '商业集团'
                },
                {
                    id: 5,
                    name: '商业地产住宅'
                }
            ],
            params:{
                page:1,
                limit:9,
                projectCaseCategoryId:''
            },
            total:0
        }
    },
     watch:{
      '$store.state.typeId'(){
            this.init()
      }
    },
    created(){
        this.init()
    },
    mounted(){
        this.getData()
    },
    // // 监听路由变化
    // watch: {
    //     $route: "urlName",
    // },
    methods:{
        init(){
            let navBarData = JSON.parse(localStorage.getItem('navBarData'))
            if(navBarData){
                this.navBarIndex = navBarData.index
                if( navBarData.id){
                    this.params.projectCaseCategoryId = navBarData.id
                    this.getNavName(navBarData.name)
                    this.getData()

                }
            }
        },
        getNavName(name){
             this.breadcrumbList = [
                {
                    name: "工程案例",
                    url: ''
                },
                {
                    name: name,
                    url: ''
                }
            ]
        },
        // 监听url参数变化
        urlName() {
            
            let navBarData = JSON.parse(localStorage.getItem('navBarData'))
            if(navBarData){
                this.getNavName(navBarData.name)
                this.navBarIndex = navBarData.index
                this.params.projectCaseCategoryId = navBarData.id
            }else{
                this.getNavName(this.$route.query.name)
                this.navBarIndex = this.$route.query.index
                this.params.projectCaseCategoryId = this.$route.query.id
            }
            this.getData()
        },
        getData(){
            this.api.findPageProjectCase(this.params).then(res=>{
                this.projectCaseList = res.data;
                this.total = res.count
            })
        },
        changeNavBar(item, index){
            this.getNavName(item.categoryName)
            document.title = item.categoryName + '—果尔佳'
            console.log(item);
            item.index = index
            this.navBarIndex = index
            this.params.projectCaseCategoryId = item.id
            localStorage.setItem('navBarData',JSON.stringify(item))
            this.params.page = 1
            this.getData()
        },
        //分页
        nextPage(page) {
            this.params.page = page
            this.getData()
        }
    }
}
</script>
<style lang="scss" scoped>


    .projectCase_page{
    .breadcrumb_box {
            background: #f4f5f5;
        }
    
        .main {
            padding-top: 51px;
            .pages{
                display: flex;
                justify-content: flex-end;
            }
        }
                .navBar {
                    margin-top: 29px;
        
                    .navBar_list {
                        width: 124px;
                        height: 46px;
                        background: #FFFFFF;
                        border: 1px solid #DEDEDE;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin: 0px 10px;
                    }
        
                    .navBarActive {
                        background: #E62129 !important;
                        border: 1px solid #E62129 !important;
                        color: #fff !important;
                    }
                }
    }
         
</style>