<template>
    <div class="projectCaseList_page">
        <div class="company_box flex flex-wrap">
            <div class="list pointer" v-for="(item,index) in companyList" :key="index" @click="toDetails(item)">
                <div style="overflow:hidden;">
                    <img class="icon_bg" :src="item.coverUrl" alt="">
                </div>
                <div class="cont">
                    <div class="company_name">{{ item.projectCaseName }}</div>
                    <div class="company_introduce astrict">{{ item.caseIntroduction }}</div>
                    <img class="icon_bg icon_n" :src="icon_n" alt="">
                    <img class="icon_bg icon_y" :src="icon_y" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        companyList:{},
        item:{},
        companyIndex:{}
    },
    data(){
        return{
            icon_n: require('@/assets/img/homes/icon_n.png'),
            icon_y: require('@/assets/img/homes/icon_y.png'),
            // companyList: [
            //     {},
            //     {},
            //     {},
            // ],
        }
    },
    mounted(){},
    methods:{
        toDetails(item){
            let navBarData = ''
            if (this.item){
                navBarData = this.item
            }else{
                 navBarData = JSON.parse(localStorage.getItem('navBarData')) 
            }
             this.$router.push({
                     path:"/projectCaseDetails",
                     query:{
                        ids:4,
                         id:item.id,
                         fatheId:  navBarData.id,
                         fatherName: navBarData.name?navBarData.name:navBarData.categoryName,
                         name: item.projectCaseName,
                         index:this.companyIndex
                     }
                 })
            // let hrefUrl = window.location.href
            //  if (hrefUrl.indexOf("/index") >= 0){
            //     let routeData = this.$router.resolve({
            //     path:"/projectCaseDetails",   
            //     query: {
            //         ids:4,
            //         id:item.id,
            //         fatheId:  navBarData.id,
            //         fatherName: navBarData.name?navBarData.name:navBarData.categoryName,
            //         name: item.projectCaseName,
            //         index:this.companyIndex,
            //         isReturn:2
            //     }
            // });
            // window.open(routeData.href, '_blank');
            
            //  }else{
            //      this.$router.push({
            //          path:"/projectCaseDetails",
            //          query:{
            //             ids:4,
            //              id:item.id,
            //              fatheId:  navBarData.id,
            //              fatherName: navBarData.name?navBarData.name:navBarData.categoryName,
            //              name: item.projectCaseName,
            //              index:this.companyIndex
            //          }
            //      })

            //  }
            
        }
    }
}
</script>
<style lang="scss" scoped>





    .projectCaseList_page{
        .company_box {
                margin-top: 39px;
        
                .list:nth-child(1) {
                    margin-left: 0 !important;
                }
        
                .list:hover {
                    .icon_bg{
                        transform: scale(1.1);
                    }
                    .company_name {
                        color: #E62129 !important;
                    }
                    .company_introduce{
                        color: #E62129 !important;
                    }
                    .icon_n{
                        display: none !important;
                    }
                    .icon_y{
                        display: block !important;
                    }
                }
                .list:nth-child(3n+1){
                    margin-left: 0px!important;
                }
                .list {
                    width: 380px;
                    height: 500px;
                    background: white;
                    margin-left: 30px;
                    margin-bottom: 33px;
                    overflow: hidden;
                    box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.2500);
                   
                    .icon_bg {
                        width: 380px;
                        height: 270px;
                        transition: All 0.8s ease;
                    }

                    .cont {
                        padding-top: 43px;
                        padding-left: 33px;
                        box-sizing: border-box;
        
                        .company_name {
                            font-size: 18px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 18px;
                        }
        
                        .company_introduce {
                            width: 306px;
                            height: 40px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            line-height: 22px;
                            margin-top: 30px;
                        }
        
                        .icon_bg {
                            width: 48px;
                            height: 34px;
                            margin-top: 36px;
                        }
                        .icon_n{
                            display: block;
                        }
                        .icon_y{
                            display: none;
                        }
                    }
                }
            }
    }
</style>