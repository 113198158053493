<template>
    <div class="navBar_page">
        <div class="navBar display">
            <div class="navBar_list display pointer" v-for="(item,index) in navBarList" :key="index"
                @click="changeNavBar(item,index)" :class="navBarIndex == index ? 'navBarActive' : ''">
                {{item.name}}
            </div>

        </div>
    </div>
</template>
<script>
export default {
    props:{
        navBarList:{}
    },
    data(){
        return{
            navBarIndex:0,
        }
    },
    mounted(){},
    methods:{
        //点击施工导航
        changeNavBar(item, index) {
            this.navBarIndex = index
            this.$emit('changeNavBar', item, index)
        },
    }
}
</script>
<style lang="scss" scoped>

    .navBar_page{
        .navBar {
                margin-top: 29px;
        
                .navBar_list {
                    width: 124px;
                    height: 46px;
                    background: #FFFFFF;
                    border: 1px solid #DEDEDE;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin: 0px 10px;
                }
        
                .navBarActive {
                    background: #E62129 !important;
                    border: 1px solid #E62129 !important;
                    color: #fff !important;
                }
            }
    }
</style>