<template>
    <div class="breadcrumb_page content flex-center">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="flex-center">
            <img class="icon_home" src="@/assets/img/serve/home.png" alt="">
            <el-breadcrumb-item :to="{ path: '/?ids=1' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
                <div class="flex-center el-breadcrumb__inner" @click="jump"> {{ item.name }}</div>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
export default {
    props: {
        breadcrumbList: {},
        returnType: {},
    },
    data() {
        return {

        }
    },
    methods: {
        jump() {
            let navBarData = JSON.parse(localStorage.getItem('navBarData'))
            let isReturn = navBarData.isReturn
            if (isReturn == 1) {
                let obj = {
                    ids: 6,
                    id: this.$route.query.fatherId,
                    name: this.$route.query.fatherId == 1 ? '公司新闻' : '行业资讯',
                    index: this.$route.query.fatherId - 1,
                    fatherName: "",
                    isReturn: 3
                }
                this.$store.commit("getTypeId", this.$route.query.fatherId)
                localStorage.setItem('navBarData', JSON.stringify(obj))
                this.$router.push({
                    path: "/news/index",
                    query: obj
                })
            } else if (isReturn == 2) {
                let obj = {
                    ids: 4,
                    id: this.$route.query.fatheId,
                    name: this.$route.query.fatherName,
                    index: this.$route.query.index,
                    fatherName: "",
                    isReturn: 3
                }
                this.$store.commit("getTypeId", this.$route.query.fatheId)
                localStorage.setItem('navBarData', JSON.stringify(obj))
                this.$router.push({
                    path: "/projectCase",
                    query: obj
                })
            } else if (isReturn == 3) {
                console.log('不用返回');
            } else {
                this.$router.back()
            }

            // this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
.breadcrumb_page {
    height: 60px;

    .icon_home {
        width: 16px;
        height: 16px;
        margin-right: 13px;
    }

    .el-breadcrumb__item {
        display: flex;
        align-items: center;
    }

    .el-breadcrumb__item {
        margin-top: 5px;

        .el-breadcrumb__inner {
            color: #666 !important;
            cursor: pointer !important;
        }
    }

    .el-breadcrumb__item:last-child {
        .el-breadcrumb__inner {
            color: #E52830 !important
        }
    }

    .el-breadcrumb__item {
        display: flex;
        align-items: center;
    }
}

.el-breadcrumb__inner {
    color: #E52830 !important
}
</style>