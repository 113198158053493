<template>
    <div>

        <div class="big" v-if="big == 1">
            <el-carousel :interval="5000" indicator-position="none">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <img class="pointer banner_img" :src="item.imgUrl" alt="" @click="bannerBtn(item.linkUrl)">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="banner_box" v-else>
            <img class="pointer banner_img" :src="bannerList[0].imgUrl" alt="" @click="bannerBtn(item.linkUrl)">
        </div>

    </div>
</template>
<script>
export default {
    props: {
        adCategory: {},
        big: {}
    },
    data() {
        return {
            bannerList: [

            ]
        }
    },
    created() {
        this.getdata(this.adCategory)
    },
    methods: {
        getdata(adCategory) {
            this.api.findAdvertise({ type: adCategory }).then(res => {
                if (res.code == 0) {
                    this.bannerList = res.data
                }
            })
        },
        bannerBtn(url) {
            if (url != '') {
                window.open(url, '_blank');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.banner_box {
    min-width: 1200px;
    height: 300px;

    .banner_img {
        width: 100%;
        height: 300px;
    }

}

.big {
    min-width: 1200px;
    height: 696px;

    ::v-deep.el-carousel__container {
        height: 696px;
    }

    .banner_img {
        width: 100%;
        height: 696px;
    }

    ::v-deep.el-carousel__arrow {
        width: 58px;
        height: 58px;
        font-size: 24px;
    }
}
</style>