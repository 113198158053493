<template>
    <div class="page_box">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="1" layout="prev, pager, next" :total="total" :page-size="params.limit ">
        </el-pagination>
    </div>
</template>
<script>
export default {
    props:{
        params:{},
        total:{}
    },
    data(){
        return{}
    },
    methods:{
        // 分页
        handleSizeChange(val) {
            console.log(val);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.$emit('nextPage',val)
        },
    }
}
</script>
<style lang="scss" scoped>

    .page_box{
        margin: 54px 0;
    }
</style>
<style >

        .el-pager li.active {
            background:#e62129 !important;
        }
    
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
            color:#e62129 !important;
        }
    
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            color: white !important;
        }
</style>